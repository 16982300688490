import React, { useEffect } from "react"
import Loader from "react-loaders"

import CustomCard from "components/Card"
import FormInput from "components/Input/formInput"
import CustomButton from "components/Button"
import TopBar from "components/TopBar"
import Breadcrumb from "components/Breadcrumb"

import { getItem } from "utils/tokenStore";
import LoginHOC from "./actions"

const Login = props => {

  useEffect(() => {
    if (getItem("EON_ACCESS_TOKEN")) {
      props.data.ProfileReducer.profile.role === 'super admin'
        ? props.history.push("/admin")
        : props.history.push("/dashboard")
    } else {
      props.setPath([
        { label: "Loan Application" },
        { label: "Login" },
      ])
    }
  }, [])

  const onToggleLogin = () => {
    let errors = []
    if( props.email?.trim() === '' ){
      errors.push({ field: 'email', message: 'Email is required.' })
    }
    if( props.password?.trim() === '' ){
      errors.push({ field: 'password', message: 'Password is required.' })
    }
    props.onChangeLoginHOC('errors', errors)

    if( errors.length === 0 ){
      props.onClickLogin({ email: props.email, password: props.password })
    }
  }
  
  return (
    <>
      <TopBar />
      <Breadcrumb pathData={props.setPath} />
      <div className="eon-main-inner">
        <div className="d-flex flex-column" style={{ padding: "32px 0px", textAlign: "center" }}>
          <span className="text-lg text-grey font-weight-heavy">Login</span>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          { props.onLoadLogin
            ? <Loader type="ball-clip-rotate" />
            : (
              <CustomCard
                mode={"default"}
                containerStyle={{ padding: "24px", gap: "12px", width: 480 }}>
                <span className="text-sm text-light-grey font-weight-light mb-2">Please fill up your information.</span>
                  <FormInput
                    type="text"
                    context="email"
                    value={props.email}
                    label="Email"
                    placeholder={"Email"}
                    errors={props.errors}
                    onKeyDown={(e) => e.keyCode === 13 && onToggleLogin()}
                    onChangeData={(val) => props.onChangeLoginHOC("email", val)}/>
                  <FormInput
                    type="password"
                    context="password"
                    value={props.password}
                    label="Password"
                    placeholder={"xxxxxxxxxxxxx"}
                    errors={props.errors}
                    onKeyDown={(e) => e.keyCode === 13 && onToggleLogin()}
                    onChangeData={(val) => props.onChangeLoginHOC("password", val)}/>
                  <CustomButton
                    buttonClass={"full-width-button mt-4"}
                    size={"lg"}
                    color={"black"}
                    onClick={() => onToggleLogin()}
                    children={"Login"}/>
                  { props.errorMessage && <span className="text-sm text-danger font-weight-light">{props.errorMessage}</span> }
                  <div className="d-flex justify-content-between align-items-center">
                    <CustomButton
                      size={"sm"}
                      color={"borderless"}
                      onClick={() => props.history.push('/reset-password-request')}
                      children={"Reset Password"}
                    />
                    <div className="text-sm text-light-grey d-flex justify-content-center">
                      <span className="font-weight-light pr-1">New User?</span>
                      <span
                        className="font-weight-light"
                        style={{ textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => props.history.push('/registration')}>
                        Sign up here.
                      </span>
                    </div>
                  </div>
              </CustomCard>
            )
          }
        </div>
      </div>
    </>
  )
}

export default LoginHOC(Login);