import React, { Component } from "react"
import { connect } from "react-redux"

import { Post } from 'utils/axios'
import { storeItem } from "utils/tokenStore";
import { setUserProfile } from "reducers/profile";
import { setCustomerProfile } from "reducers/customerProfile";
import { setPath } from 'actions/path'
import { requestError, requestSuccess } from 'utils/requestHandler'

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showTACPage: false,

      userAccount: {
        email: "",
        username: "",
        phone_no: "",
        password: "",
        password_confirmation: "",
        tac: ['','','',''],
        staff_id: null,
        role: ""
      },
    }

    load = (param) => this.setState({ loading: param })
    onChangeRegisterHOC = (key, val) => this.setState({ [key]: val })

    verifyUserAccount = dataToSubmit => Post(
      `/api/verifications`,
      {
        subject: "mobile_number",
        mobile_number: dataToSubmit.phone_no,
        email: dataToSubmit.email,
        role: dataToSubmit.role,
        staff_id: dataToSubmit.staff_id
      },
      this.verifyUserAccountSuccess,
      this.verifyUserAccountError,
      this.load
    )
    verifyUserAccountSuccess = response => {
      requestSuccess(response.message)
      this.setState({ showTACPage: true })
    }
    verifyUserAccountError = error => requestError(error.message)

    accountRegistration = dataToSubmit => Post(
      `/api/registrations`,
      dataToSubmit,
      this.accountRegistrationSuccess,
      this.accountRegistrationError,
      this.load
    )
    accountRegistrationSuccess = response => {
      this.props.setUserProfile(response.data)
      storeItem("EON_ACCESS_TOKEN", response.token);
      if( response.data.role === 'sales agent' ){
        this.props.history.push("/dashboard/client-selection");
      } else if ( response.data.role === 'customer' ){
        this.props.setCustomerProfile(response.data)
        this.props.history.push("/dashboard/car-booking");
      }
    }
    accountRegistrationError = error => requestError(error.message)

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadRegistration={this.state.loading}

          verifyUserAccount={this.verifyUserAccount}
          accountRegistration={this.accountRegistration}
          onChangeRegisterHOC={this.onChangeRegisterHOC} />
      )
    }
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    setPath,
    setUserProfile,
    setCustomerProfile
  })(WithHOC)
}
export default HOC

