import React, { useEffect } from "react"
import { TiTick } from "react-icons/ti"

import CustomCard from "components/Card"
import FormInput from "components/Input/formInput"
import CustomButton from "components/Button"
import TopBar from "components/TopBar"
import Breadcrumb from "components/Breadcrumb"
import CustomAlert from "components/Alert"
import LoadingOverlay from "components/Loading"

import ResetPwHOC from "./actions"

const ResetPassword = props => {
  useEffect(() => {
    props.setPath([
      { label: "Loan Application" },
      { label: "Reset Password" },
    ])
  }, [])
  
  return (
    <>
      <TopBar />
      <Breadcrumb pathData={[]} />
      <div className="eon-main-inner">
        <div className="d-flex flex-column" style={{ padding: "32px 0px", textAlign: "center" }}>
          <span className="text-lg text-grey font-weight-heavy">Reset Password</span>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <CustomCard
            mode={"default"}
            containerStyle={{ padding: "24px", gap: "12px", width: "450px" }}>
            <span className="text-sm text-light-grey font-weight-light mb-0">Forgotten your password?</span>
            <span className="text-sm text-light-grey font-weight-light mb-2">We'll email you instructions to reset your password.</span>
            <FormInput
              type="text"
              context="email"
              value={props.email}
              label="Email"
              placeholder={"Email"}
              errors={props.errors}
              onChangeData={(val) => props.onChangeResetPwHOC("email", val)}
            />
            <CustomAlert color={"success"} isOpen={props.showResetPwAlert}>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center text-sm font-weight-light">
                  <TiTick style={{ fontSize: "24px", marginRight: "8px" }}/>
                  Reset password email is sent. Please check your email.
                </div>
              </div>
            </CustomAlert>
            <CustomButton
              buttonClass={"full-width-button mt-2"}
              size={"lg"}
              color={"black"}
              disabled={props.email.length < 1}
              onClick={() => {
                if (props.showResetPwAlert) {
                  props.history.push('/login')
                } else {
                  props.sendResetPwEmail({ email: props.email })
                  props.onChangeResetPwHOC("loading", true)
                }
              }}
            >
              {props.showResetPwAlert ? "Back to Login Page" : "Send Email"}
            </CustomButton>
          </CustomCard>
        </div>
      </div>
      {props.loading && <LoadingOverlay />}
    </>
  )
}

export default ResetPwHOC(ResetPassword)