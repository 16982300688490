import React, { useEffect } from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import { Switch, Route, withRouter, Redirect } from "react-router-dom"

import Login from "containers/Login"
import Registration from "containers/Register"
import ResetPassword from "containers/ResetPassword"
import NewPassword from "containers/NewPassword"
import Dashboard from "containers/Dashboard"

import { getItem } from "utils/tokenStore"
import { getProfile } from 'actions/profile';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
)

function CustomRouter(props) {
  useEffect(()=>{
    if( getItem( "EON_ACCESS_TOKEN" ) ){
      props.getProfile();
      if ( props.history.location.pathname === '/dashboard' ) {
        if( props.data.ProfileReducer.profile.role === 'sales agent' ){
          return props.history.push("/dashboard/client-selection");
        } 
        if ( props.data.ProfileReducer.profile.role === 'customer' ) {
          return props.history.push("/dashboard/car-booking");
        }
        if ( props.data.ProfileReducer.profile.role === 'admin' ) {
          return props.history.push("/admin/user_management");
        }
      }
    }
  },[])

  return (
    <Switch>
      <Route exact path="/login" component={Login} initialPath />
      <Route exact path="/registration" component={Registration} />
      <Route exact path="/reset-password-request" component={ResetPassword} />
      <Route exact path="/reset-password" component={NewPassword} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <Redirect from={"/"} to={"/login"} />
    </Switch>
  )
}

const mapStateToProps = (state) => ({ data: state })
export default compose(
  connect(mapStateToProps, { getProfile }), 
  withRouter
)(CustomRouter)