import React, { useEffect, useState } from "react"
import _ from "lodash"
import { Row, Col } from "antd"
import { BsCheckCircleFill } from "react-icons/bs"

import CustomCard from "components/Card"
import CustomRadioButton from "components/Input/radio"
import FormInput from "components/Input/formInput"
import CustomButton from "components/Button"

const RegisterForm = props => {

  const [passwordFullfilled, setPasswordFullfilled] = useState(false)

  useEffect(() => {
    if(props.userAccount?.password?.length > 7){
      setPasswordFullfilled(true)
    } else {
      setPasswordFullfilled(false)
    }
  }, [props.userAccount?.password])

  const onChangeData = (key, val) => {
    let temp = _.cloneDeep(props.userAccount)
    temp[key] = val
    if( key === 'role' && val === 'customer' ) {
      temp.staff_id = null
    }
    props.onChangeRegisterHOC("userAccount", temp)
  }

  const onToggleVerification = () => {
    let errors = []
    Object.keys(props.userAccount).map(key => {
      if( key === 'role' && props.userAccount[key] === 'sales agent' && !props.userAccount.staff_id ){
        errors.push({field: 'staff_id', message: 'Staff ID is required'})
      } else if ( key === 'password_confirmation' && props.userAccount[key] !== props.userAccount.password) {
        errors.push({field: key, message: 'Password does not match.'})
      } else if( key !== 'staff_id' && props.userAccount[key] === ''  ){
        errors.push({field: key, message: 'This field is required.'})
      }
    })

    if( errors.length === 0 ){
      props.verifyUserAccount(props.userAccount)
    } else {
      props.onChangeRegisterHOC("errors", errors)
    }
  }

  return (
    <>
      <CustomCard
        mode={"default"}
        containerStyle={{ padding: "24px", gap: "12px" }}>
        <div className="d-flex flex-column">
          <span className="text-lg text-grey font-weight-heavy">{props.userAccount.role === "sales agent" ? 'Agent' : 'Client'} Account</span>
          <span className="text-sm text-light-grey font-weight-light">Please fill up your information.</span>
        </div>
        <CustomCard
          mode={props.userAccount.role === "customer" ? "radio" : "default"}
          onClick={() => onChangeData( 'role', 'customer')}
          containerStyle={{ padding: "24px 16px", cursor: "pointer" }}>
          <CustomRadioButton
            label={"I am a customer"}
            checked={props.userAccount.role === "customer"}
            onChange={() => onChangeData('role', 'customer')}
          />
        </CustomCard>
        <CustomCard
          mode={props.userAccount.role === "sales agent" ? "radio" : "default"}
          onClick={() => onChangeData( 'role', 'sales agent')}
          containerStyle={{ padding: "24px 16px", cursor: "pointer" }}>
          <CustomRadioButton
            label={"I am a sales agent."}
            checked={props.userAccount.role === "sales agent"}
            onChange={() => onChangeData('role', 'sales agent')}
          />
        </CustomCard>
        <Row gutter={16}>
          <Col span={props.userAccount?.role === 'sales agent' ? 12 : 24}>
            <FormInput
              type="text"
              context="username"
              value={props.userAccount.username}
              label="Full Name"
              placeholder={"Name"}
              required={false}
              errors={props.errors}
              onChangeData={(val) => onChangeData('username', val)}
            />
          </Col>
          { props.userAccount?.role === 'sales agent' && (
            <Col span={12}>
              <FormInput
                type="text"
                context="staff_id"
                value={props.userAccount.staff_id}
                label="Staff ID"
                placeholder={"Staff ID"}
                required={false}
                errors={props.errors}
                onChangeData={(val) => onChangeData('staff_id', val)}
              />
            </Col>
          ) }
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <FormInput
              type="text"
              context="phone_no"
              value={props.userAccount.phone_no}
              label="Contact No"
              prefix={"+60"}
              required={false}
              errors={props.errors}
              onChangeData={(val) => onChangeData('phone_no', val)}
            />
          </Col>
          <Col span={12}>
            <FormInput
              type="text"
              context="email"
              value={props.userAccount.email}
              label="Email"
              placeholder={"Email"}
              required={false}
              errors={props.errors}
              onChangeData={(val) => onChangeData('email', val)}
            />
          </Col>
        </Row>
        <FormInput
          type="password"
          context="password"
          value={props.userAccount.password}
          label="Password"
          placeholder={"Enter Password"}
          required={false}
          errors={props.errors}
          onChangeData={(val) => onChangeData('password', val)}
          instruction={
            <span style={{fontSize: 12, color: passwordFullfilled ? 'green' : '#dddddd'}}>
              <BsCheckCircleFill style={{
                marginRight: '8px',
                borderRadius: '50%',
                color: passwordFullfilled ? 'green' : '#dddddd',
                backgroundColor: passwordFullfilled ? 'white' : '#dddddd'
              }} />
              Min 8 characters
            </span>
          }
        />
        <FormInput
          type="password"
          context="password_confirmation"
          value={props.userAccount.password_confirmation}
          label="Confirm Password"
          placeholder={"Confirm Password"}
          required={false}
          errors={props.errors}
          onChangeData={(val) => onChangeData('password_confirmation', val)}
        />
        <CustomButton
          buttonClass={"full-width-button mt-4"}
          size={"lg"}
          color={"black"}
          onClick={() => onToggleVerification()}
          children={"Create an Account"}
        />
        <div className="text-sm text-light-grey d-flex justify-content-center">
          <span className="font-weight-light pr-1">Having an existing account?</span>
          <span
            className="font-weight-light"
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => props.history.push('/login')}>
            Log in here.
          </span>
        </div>
      </CustomCard>
    </>
  )
}

export default RegisterForm