import React, { lazy } from "react";

const AgentCarBooking = lazy(() => import("./AgentCarBooking"));
const ClientSelection = lazy(() => import("./ClientSelection"));

export const routes = [
  {
    path: "/car-booking",
    exact: true,
    render: (props) => <AgentCarBooking {...props} />,
  },
  {
    path: "/client-selection",
    exact: true,
    render: (props) => <ClientSelection {...props} />,
  },
];