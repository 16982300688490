import React, { Component } from "react"
import { connect } from "react-redux"
import Axios from "axios";

import { setPath } from 'actions/path'
import { storeItem } from "utils/tokenStore";
import { setUserProfile } from "reducers/profile";
import { setCustomerProfile } from "reducers/customerProfile";
import getDomainURL from "utils/api";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      email: "",
      password: "",
      errors: []
    }

    load = (param) => this.setState({ loading: param })
    onChangeLoginHOC = (key, val) => this.setState({ [key]: val })

    onClickLogin = (dataToSubmit) => {
      this.setState({
        loading: true,
        errorMessage: "",
      }, () => {
        Axios.post(`${getDomainURL()}/login`, dataToSubmit)
          .then((response) => {
            this.setState({ loading: false });
            if( response.data.data.role === 'customer' ){
              this.props.setCustomerProfile( response.data.data )
            }
            this.props.setUserProfile( response.data.data )
            storeItem("EON_ACCESS_TOKEN", response.data.token);
            
            window.location.reload()
          })
          .catch((err) => {
            this.setState({
              errorMessage:
                err.response?.data?.message ??
                "This combination of username and password is incorrect.",
              loading: false,
            });
          });
      });
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadLogin={this.state.loading}

          onClickLogin={this.onClickLogin}
          onChangeLoginHOC={this.onChangeLoginHOC} />
      )
    }
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    setPath,
    setUserProfile,
    setCustomerProfile
  })(WithHOC)
}
export default HOC