import React, { useEffect, useState } from "react"
import { useLocation } from 'react-router-dom'
import { BiErrorCircle } from "react-icons/bi"
import { BsCheckCircleFill } from "react-icons/bs"
import _ from "lodash"

import FormInput from "components/Input/formInput"
import CustomButton from "components/Button"
import CustomIcon from "components/Icon"
import TopBar from "components/TopBar"
import Breadcrumb from "components/Breadcrumb"
import CustomCard from "components/Card"
import LoadingOverlay from "components/Loading"
import CustomAlert from "components/Alert"

import NewPwHOC from "./actions"

const NewPassword = props => {
  const useQuery = () => new URLSearchParams(useLocation().search)
  let query = useQuery()
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showTokenError, setShowTokenError] = useState(false)
  const [passwordFullfilled, setPasswordFullfilled] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  useEffect(() => {
    props.setPath([
      { label: "Loan Application" },
      { label: "Reset Password" },
    ])

    if (_.isEmpty(query.get("token")) || query.get("token") === null || query.get("token").length < 1) {
      setShowTokenError(true)
    }
  }, [])

  useEffect(() => {
    if(props.newPassword?.length > 7){
      setPasswordFullfilled(true)
    } else {
      setPasswordFullfilled(false)
    }
  }, [props.newPassword])

  return (
    <>
      <TopBar />
      <Breadcrumb pathData={[]} />
      <div className="eon-main-inner">
        <div className="d-flex flex-column" style={{ padding: "32px 0px", textAlign: "center" }}>
          <span className="text-lg text-grey font-weight-heavy">Reset Password</span>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <CustomCard
            mode={"default"}
            containerStyle={{ padding: "24px", gap: "12px", width: "450px" }}>
            <CustomAlert color={"danger"} isOpen={showTokenError}>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center text-sm font-weight-light">
                  <BiErrorCircle style={{ fontSize: "24px", marginRight: "8px" }}/>
                  Token is not found or invalid!
                </div>
              </div>
            </CustomAlert>
            <div className="d-flex justify-content-between">
              <label className="mb-0">New Password</label>
              <CustomButton
                size={"sm"}
                color={"borderless"}
                onClick={() => setShowNewPassword((show) => !show)}>
                {showNewPassword ?
                  <CustomIcon antIcon="EyeOutlined" /> :
                  <CustomIcon antIcon="EyeInvisibleOutlined" />}
              </CustomButton>
            </div>
            <FormInput
              type={showNewPassword ? "text" : "password"}
              context="password"
              value={props.newPassword}
              placeholder={"Enter New Password"}
              errors={props.errors}
              onChangeData={val => props.onChangeNewPwHOC("newPassword", val)}
              instruction={
                <span style={{fontSize: 12, color: passwordFullfilled ? 'green' : '#dddddd'}}>
                  <BsCheckCircleFill style={{
                    marginRight: '8px',
                    borderRadius: '50%',
                    color: passwordFullfilled ? 'green' : '#dddddd',
                    backgroundColor: passwordFullfilled ? 'white' : '#dddddd'
                  }} />
                  Min 8 characters
                </span>
              }
            />

            <div className="d-flex justify-content-between">
              <label className="mb-0">Confirm New Password</label>
              <CustomButton
                size={"sm"}
                color={"borderless"}
                onClick={() => setShowConfirmPassword((show) => !show)}>
                {showConfirmPassword ?
                  <CustomIcon antIcon="EyeOutlined" /> :
                  <CustomIcon antIcon="EyeInvisibleOutlined" />}
              </CustomButton>
            </div>
            <FormInput
              type={showConfirmPassword ? "text" : "password"}
              context="password_confirmation"
              value={props.confirmNewPassword}
              placeholder={"Reenter New Password"}
              errors={props.errors}
              onChangeData={val => props.onChangeNewPwHOC("confirmNewPassword", val)}
            />

            <CustomButton
              buttonClass={"full-width-button mt-2"}
              size={"lg"}
              color={"black"}
              disabled={
                props.newPassword.length < 1 ||
                props.confirmNewPassword.length < 1 ||
                props.newPassword !== props.confirmNewPassword ||
                showTokenError
              }
              onClick={() => {
                props.setNewPassword(
                  { password: props.newPassword, password_confirmation: props.confirmNewPassword },
                  query.get("token")
                )
              }}>
              Reset Password
            </CustomButton>
          </CustomCard>
        </div>
      </div>
      {props.loading && <LoadingOverlay />}
    </>
  )
}

export default NewPwHOC(NewPassword)