import { GET_CUSTOMER_PROFILE } from "../actions/type";

const initialState = {
  customerProfile: {},
};

export const setCustomerProfile = payload => ({
  type: GET_CUSTOMER_PROFILE,
  payload,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_PROFILE:
      return {
        ...state,
        customerProfile: action.payload,
      };
    default:
      return state;
  }
};
