import React, { useState } from 'react'
import { connect } from "react-redux"
import { compose } from "redux"
import { Button } from "reactstrap"
import { Avatar } from "antd"

import CustomAvatar from "components/Avatar"

import { clearItem, getItem } from "utils/tokenStore"
import { setUserProfile } from "reducers/profile";
import { setCustomerProfile } from "reducers/customerProfile"

import EONLogo from 'assets/Images/eon-svg.svg'

import './index.scss'

function TopBar(props) {
  const { profile } = props.data.ProfileReducer

  const onToggleLogOut = () => Promise.all([
    clearItem("EON_ACCESS_TOKEN"),
    props.setUserProfile({}),
    props.setCustomerProfile({})
  ]).then(() => {
    window.location.reload()
  });
  
  return (
    <>
      <div className="eon-topbar-cont grid__wrapper">
        <div
          className="eon-topbar-logo-cont"
          onClick={() => window.open('https://eon.com.my/')}>
          <img src={ EONLogo } alt="eon-logo" />
        </div>
        { getItem("EON_ACCESS_TOKEN") && (
          <div className="eon-topbar-cart-cont">
            <div className="d-flex align-items-center" style={{ gap: "16px" }}>
              { profile?.photo 
                ? <CustomAvatar
                    imageUrl={profile?.photo}
                    imageWidth="32px"
                    imageHeight="32px" />
                : <Avatar size={32} children={profile?.username?.[0].toUpperCase()} />
              }
              <Button
                className="font-inter"
                style={{ color: "#ffffff" }}
                size={"sm"}
                color={"dark"}
                onClick={() => onToggleLogOut()}
                children={"Logout"}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({ data: state });
export default compose(
  connect(mapStateToProps, {
    setUserProfile,
    setCustomerProfile
  }), 
)(TopBar);